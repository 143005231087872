// eslint-disable-next-line
export default (CURRENT_ENV, country) => ({
  config: 'common',
  defaultJurisdiction: 'mga',
  CURRENT_ENV,
  type: 'pnp',
  signUpVersion: 'v2',
  indexContainer: 'IndexContainerPnp',
  skinId: CURRENT_ENV === 'prod' ? 67 : 122,
  defaultLocale: 'fi-FI',
  MERCHANT_ID: CURRENT_ENV === 'prod' ? 100014044 : 100014982,
  content: {
    space: 'bqaEy8xoaiqt',
    host: 'https://delivery.objectic.io',
    access_token: 'WZVjsKGzceFGp8txJHGHlyiaHmnvV4KCjIZSQwbY',
    preview_host: 'https://preview.objectic.io',
    preview_access_token: 'WZVjsKGzceFGp8txJHGHlyiaHmnvV4KCjIZSQwbY',
    environment: CURRENT_ENV === 'prod' ? 'master' : 'stage',
  },
  games: {
    space: 'jIWXpQOLRVBo',
    host: 'https://delivery.objectic.io',
    access_token: '768|pmGig0SgkbP6dNn8LNtdxfCGSSqGHsfJSLhXlCBc',
    preview_host: 'https://preview.objectic.io',
    preview_access_token: '768|pmGig0SgkbP6dNn8LNtdxfCGSSqGHsfJSLhXlCBc',
    environment: CURRENT_ENV === 'prod' ? 'master' : 'master',
    gameIdKey: CURRENT_ENV === 'prod' ? 'tg-pz/senshiro/helmicasino' : 'tg-pz-stage/senshiro/helmicasino',
  },
  axiosCache: {
    duration: 60, // 1 minute
    whitelist: ['delivery.objectic.io', '/event-feed/jackpots'],
  },
  jurisdictions: [
    {
      key: 'mga',
      name: 'helmicasino.com',
      domain: CURRENT_ENV === 'prod' ? 'www.helmicasino.com' : null,
    },
  ],
  tgplay: {
    host:
      CURRENT_ENV === 'prod'
        ? 'https://play.helmicasino.com'
        : 'https://play-togethergaming-new-config-stage.azurewebsites.net',
    skin: 'helmicasino',
    standalone: true,
  },
  aleacc: {
    host:
      CURRENT_ENV === 'prod'
        ? 'https://api.helmicasino.com'
        : 'https://api-stage-helmicasino.codebet.dev',
  },
  devcode: {
    host:
      CURRENT_ENV === 'prod'
        ? 'https://api.paymentiq.io/paymentiq/api'
        : 'https://test-bo.paymentiq.io/paymentiq/api',
    oauth_host:
      CURRENT_ENV === 'prod'
        ? 'https://backoffice.paymentiq.io/paymentiq/oauth'
        : 'https://test-api.paymentiq.io/paymentiq/oauth',
    oauth_provider: 'trustly',
    oauth_clientid:
      CURRENT_ENV === 'prod'
        ? '860e9d26d71346f08d8ac41f0f2a6127'
        : '93b0a85e773e475fa4c0f4bbdeb3b2f6',
    oauth_redirecturl:
      CURRENT_ENV === 'prod'
        ? 'https://www.helmicasino.com/nemid.html'
        : 'https://stage-helmicasino.codebet.dev/nemid.html',
  },
  disableActivation: true,
  hideLimitStep: true,
  piqCashier: {
    predefinedAmounts: {
      eur: [30, 120, 777],
      cad: [30, 120, 777],
      nzd: [30, 120, 777],
      nok: [300, 1200, 7777],
    },
    showAmountLimits: true,
    showFee: false,
    theme: {
      input: {
        borderRadius: '4px',
      },
      labels: {
        color: '#000',
      },
      headings: {
        color: '#000',
      },
      loader: {
        color: '#42d19d',
      },
      buttons: {
        color: '#42d19d',
      },
      headerbackground: {
        color: '#06043a',
      },
      background: {
        color: '#06043a',
      },
      cashierbackground: {
        color: '#e3e3e3',
      },
    },
    css: `
        #cashier .footer {
          border-top: none;
        }
      `,
  },
  freshChat: {
    token: 'c44b51b7-1e7b-4df7-8009-72478339d0df',
    host: 'https://wchat.eu.freshchat.com',
    brand: 'helmicasino',
    appName: 'helmicasino',
    appImage:
      CURRENT_ENV === 'prod'
        ? 'https://www.helmicasino.com/skin/images/favicon.ico'
        : 'https://www-helmicasino-stage.azurewebsites.net/skin/images/favicon.ico',
    siteId: 'helmicasino',
    tags: ['welcomehelmicasino', 'issuehelmicasino'],
    config: {
      headerProperty: {
        backgroundColor: '#5fa9c1',
        foregroundColor: '#FFFFFF',
      },
      cssNames: {
        widget: 'fc_frame',
        open: 'fc_open',
      },
    },
  },
  quickDeposit: {
    enabled: false,
    host: 'https://togethergamingcdn.azureedge.net/tg-quickdeposit/v0.13/tg-quickdeposit.bundle.js',
    successUrl:
      'https://togethergamingcdn.azureedge.net/tg-quickdeposit/v0.13/success.html',
    failureUrl:
      'https://togethergamingcdn.azureedge.net/tg-quickdeposit/v0.13/failure.html',
    cancelUrl:
      'https://togethergamingcdn.azureedge.net/tg-quickdeposit/v0.13/cancel.html',
  },
  /**
   * Configure which chat to use.
   * @description
   * Current available chat options are FreshChat (freshchat), LiveAgent (ladesk), LiveChat (livechat).
   */
  chat: {
    provider: 'zendesk',
    host: 'https://static.zdassets.com/ekr/snippet.js?key=4a42845a-3cef-4f09-8653-48edf285c611',
    secret: '9DC9F1C05BCE1B215BB934F9A2256FDA5DDBE0FF22DDE0FD8348098AD04A3CC1',
    departments: [
      'Helmicasino English Support',
      'Helmicasino Asiakaspalvelu Suomeksi',
    ],
    tags: ['helmicasino'],
    colors: {
      theme: '#2ca6ff',
      launcher: '#2ca6ff',
      launcherText: '#ffffff',
      button: '#2ca6ff',
    },
  },
  /**
   * Visibility configuration for user registration.
   * @description
   * Current available elements for the array: ['email', 'phone']
   */
  userFields: ['email', 'phone'],
  /**
   * Validation rules used for the email field in the details step.
   * @description
   * Current available rules: "validationPattern", "transformationPattern", "required"
   */
  emailValidationRules: {
    validationPattern:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    transformationPattern: null,
    required: true,
  },
  /**
   * Validation rules used for the phone field in the details step.
   * @description
   * Current available rules: "validationPattern", "transformationPattern", "required"
   */
  phoneValidationRules: {
    validationPattern: /^[0-9]{9,}$/,
    transformationPattern: /^0+/,
    required: true,
  },
  /**
   * How the index page features should be displayed
   * @description
   * Current available options are 'row', 'slider', 'textSlider' and 'iconSlider'
   * Default :
   * desktop: 'row',
   * mobile: 'slider'
   */
  indexFeatures: {
    desktop: 'row',
    mobile: 'slider',
  },

  showBonusSelector: true,
  onlyShowBonusOnSignUp: true,

  /**
   * Custom logo url
   */
  logoUrl: '/skin/images/logo.svg',

  /**
   * If the sign in/fetch balance button should be displayed in header or base page.
   * @description
   * Default is true which means it should be in header.
   * showFetchBalanceIndexHeader: true
   */
  showFetchBalanceIndexHeader: true,

  globalNavContainer: false,

  products: ['casino', 'payments'],

  hideOnScroll: {
    footer: false,
  },

  /**
   * Show sticky footer on desktop
   */
  showStickyFooter: false,

  /**
   * Show sticky footer sign up CTA
   */
  stickyFooterButton: false,

  forceDashboard: true,

  /**
   * Show sticky footer on the index page, will also hide the hamburger
   */
  stickyFooterOnIndex: true,

  /*
   * Choose if support button in header/dashboard should navigate to page or open support widget
   */
  openSupportWidget: {
    header: false,
    sidebar: true,
  },

  /**
   * Casino options
   * @property layout Game grid layout (slider, grid)
   * @property searchStyle Show casino categories (categories, noCategories)
   */
  casino: {
    layout: 'slider',
    casinoCategoryMenuStyle: 'categories',
    liveCasinoData: true,
  },

  redirectCasino: true,
  preGameView: {
    signedIn: true,
    signedOut: true,
  },

  /**
   * Options for communication consent checkbox on details step.
   */
  communicationConsent: {
    enabled: true,
    values: [
      'BonusEmails',
      'BonusSMSs',
      'NewsEmails',
      'NewsSMSs',
      'Phone',
      'Casino',
      'Sportsbook',
    ],
  },

  visitedCookieName: 'tg-page-visited',
  visitedCookieMaxAge: 365 * 24 * 60 * 60 * 1000,
  gameHistoryName: 'tg-game-history',
  btagCookie: 'tg-btag',
  defaultCurrency: 'EUR',

  GTM_ID: 'GTM-W8Z6P6G',

  redirects: [
    {
      source: /^\/terms-and-conditions/,
      target: () => '/#popup/terms-and-conditions',
    },
    {
      source: '/unsub',
      target: () => '/fetch-balance?ref_url=dashboard/subscriptions',
    },
    {
      source: '/deposit',
      target: () => '/start-playing',
    },
    {
      source: '/withdraw',
      target: () => '/#withdraw',
    },
    {
      source: /^\/(sign-in|login)$/,
      target: () => '/fetch-balance',
    },
    {
      source: '/sign-up',
      target: () => '/#sign-up',
    },
    {
      source: '/dashboard',
      target: () => '/#dashboard',
    },
    {
      source: /^\/(dashboard\/activate|activate)$/,
      target: () => '/#dashboard/activate',
    },
    {
      source: '/dashboard/responsible-gaming',
      target: () => '/#dashboard/responsible-gaming',
    },
    {
      source: '/dashboard/subscriptions',
      target: () => '/#dashboard/subscriptions',
    },
    {
      source: '/dashboard/verify',
      target: () => '/#dashboard/verify',
    },
    {
      source: '/dashboard/bonus',
      target: () => '/#dashboard/bonus',
    },
    {
      source: '/dashboard/history',
      target: () => '/#dashboard/history',
    },
    {
      source: '/dashboard/messages',
      target: () => '/#dashboard/messages',
    },
    {
      source: '/dashboard/edit-profile',
      target: () => '/#dashboard/edit-profile',
    },
    {
      source: '/dashboard/change-password',
      target: () => '/#dashboard/change-password',
    },
    {
      source: '/sbtech/sbtech.js',
      target: () => '/sbtech.js',
    },
  ],
  /**
   * @param {Request} [req] Provide the request object if this function is being used on the server
   * @returns {(string|boolean)}
   */
  cookieDomain: (req) => {
    if (!__CLIENT__ && !req) return false;
    return (__CLIENT__ ? window.location.href : req.get('host')).includes(
      'helmicasino.com'
    )
      ? '.helmicasino.com'
      : false;
  },
});
